import * as React from 'react'
import type { ZodError } from 'zod'
import { z } from 'zod'
import { zx } from 'zodix'

import type { ActionArgs, LoaderArgs, V2_MetaFunction } from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'

import { createUserSession, getUserId } from '~/models/session.server'
import { verifyLogin } from '~/models/user.server'
import { safeRedirect } from '~/utils'

export async function loader({ request }: LoaderArgs) {
  try {
    const userId = await getUserId(request)
    if (userId) return redirect('/')
    return json({})
  } catch (err) {
    console.log('\n login page error...', err)
    return json({
      isError: true,
      error: err
    })
  }
}

export async function action({ request }: ActionArgs) {
  try {
    const userInput = await zx.parseFormSafe(request, {
      email: z
        .string()
        .trim()
        .email({ message: 'Email is invalid' })
        .toLowerCase(),
      password: z.string({ required_error: 'Password is required' }).min(8, { message: 'Password must be 8 characters long' }),
      redirectTo: z.string().default('/').transform(safeRedirect),
      remember: zx.CheckboxAsString,
    })

    if (!userInput.success) {
      return json({ errors: userInput.error.issues }, { status: 400 })
    }

    const { email, password, redirectTo, remember } = userInput.data

    const user = await verifyLogin(email, password)
    if (!user) {
      return json(
        {
          errors: [
            {
              message: 'Invalid email or password',
              code: 'custom',
              path: ['invalidUser'],
            },
          ] as ZodError['issues'],
        },
        { status: 400 }
      )
    }

    return createUserSession({
      request,
      user,
      remember,
      redirectTo,
    })
  } catch (err: any) {
    const message = err && err.message ? err.message : 'Something went wrong please try again'
    console.log('\n login action error:', message)
    return json(
      {
        errors: [
          {
            message: message,
            code: 'custom',
            path: ['unknownError'],
          },
        ] as ZodError['issues'],
      },
      { status: 400 }
    )
  }
}

export const meta: V2_MetaFunction = () => [{ title: 'Login' }]

export default function LoginPage() {
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo') || '/'
  const actionData = useActionData<typeof action>()
  const emailRef = React.useRef<HTMLInputElement>(null)
  const passwordRef = React.useRef<HTMLInputElement>(null)

  const invalidUserError = actionData?.errors.find((e) =>
    e.path.includes('invalidUser')
  )
  const unknowError = actionData?.errors.find((e) =>
    e.path.includes('unknownError')
  )
  const emailError = actionData?.errors.find((e) => e.path.includes('email'))
  const passwordError = actionData?.errors.find((e) =>
    e.path.includes('password')
  )

  React.useEffect(() => {
    try {
      if (emailError || invalidUserError) {
        emailRef.current?.focus()
      } else if (passwordError) {
        passwordRef.current?.focus()
      }
    } catch (err) { }
  }, [invalidUserError, emailError, passwordError])

  return (
    <main className="authBody-holder py-5">
      <section className="mt-0 overflow-hidden bg-white xl:pb-0 w-full">
        <div className="container mx-auto px-4">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="font-heading tracking-px-n mb-[45px] text-center text-6xl font-bold leading-tight text-primary md:text-7xl">
              Login
            </h2>

            <Form method="post">
              <label className="mb-5 block text-left">
                <div className="flex space-x-2">
                  <p className="pb-2 font-medium text-[#525252]">Email</p>
                  <p className="font-bold text-primary">*</p>
                </div>
                <input
                  className="w-full rounded-lg border border-primary bg-white px-4 py-3.5 font-medium text-gray-500 placeholder-gray-500 outline-none focus:ring focus:ring-green-700"
                  ref={emailRef}
                  id="email"
                  required
                  autoFocus={true}
                  name="email"
                  type="email"
                  autoComplete="email"
                  aria-invalid={
                    emailError || invalidUserError ? true : undefined
                  }
                  aria-describedby="email-error"
                  placeholder="Email Address"
                />
                {emailError && (
                  <div className="pt-1 text-red-700" id="email-error">
                    {emailError.message}
                  </div>
                )}
                {invalidUserError && (
                  <div className="pt-1 text-red-700" id="email-error">
                    {invalidUserError.message}
                  </div>
                )}
              </label>

              <label className="mb-5 block text-left">
                <div className="flex space-x-2">
                  <p className="pb-2 font-medium text-[#525252]">Password</p>
                  <p className="font-bold text-primary">*</p>
                </div>
                <input
                  className="w-full rounded-lg border border-primary bg-white px-4 py-3.5 font-medium text-gray-500 placeholder-gray-500 outline-none focus:ring focus:ring-green-700"
                  id="password"
                  ref={passwordRef}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  aria-invalid={passwordError ? true : undefined}
                  aria-describedby="password-error"
                  placeholder="Password"
                />
                {passwordError && (
                  <div className="pt-1 text-red-700" id="password-error">
                    {passwordError?.message}
                  </div>
                )}
                {unknowError && (
                  <div className="pt-1 text-red-700" id="password-error">
                    {unknowError?.message}
                  </div>
                )}
              </label>


              <input type="hidden" name="redirectTo" value={redirectTo} />
              <button
                className="hover:shadow-4xl mb-8 w-full rounded-xl border bg-secondary py-4  px-9 font-semibold text-white shadow-sm transition duration-200 hover:bg-red-700 ease-in-out focus:ring focus:ring-indigo-300"
                type="submit"
              >
                Login
              </button>
              <p className="space-x-2 mb-2 font-medium">

                <Link
                  className="text-primary hover:text-green-700 "
                  to="/forgot-password"
                >
                  Forgotten password?
                </Link>
              </p>
              <p className="space-x-2 font-medium">
                <span>Don't have an account?</span>
                <Link
                  className="text-primary hover:text-green-700 "
                  to="/signup"
                >
                  Sign up
                </Link>
              </p>
            </Form>
          </div>
        </div>
      </section>
    </main>
  )
}
